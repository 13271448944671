/* eslint-disable */

<template>
  <div>
    <div class="vx-row" :style="expandPage === true ? 'margin-bottom: 17rem' : ''">
      <div class="vx-col w-full">
        <vx-card class="mb-base pr-4 pl-4">
          <div class="vx-row">
            <div class="vx-col w-1/3">
              <div class="m-3">
                <div class="p-4 border-2 border-solid rounded" style="background: #f368e0">
                  <h2>Name: <span class="font-bold break-all">CandyManCarl.Local</span></h2>

                  <div class="mt-3">
                    <div class="flex items-center mb-1">
                      <feather-icon
                        v-if="candyManCarl.roleIsCorrect === true"
                        icon="CheckCircleIcon"
                        svgClasses="w-6 h-6 text-success"
                        class="mr-1"
                      ></feather-icon>
                      <feather-icon
                        v-if="candyManCarl.roleIsCorrect === false"
                        icon="XCircleIcon"
                        svgClasses="w-6 h-6 text-danger"
                        class="mr-1"
                      ></feather-icon>
                      <h4>Role</h4>
                    </div>
                    <v-select placeholder="Select Role" class="w-full" :options="roleOptions" v-model="candyManCarl.role" />
                  </div>

                  <div class="mt-3">
                    <div class="flex items-center mb-1">
                      <feather-icon
                        v-if="candyManCarl.ipIsCorrect === true"
                        icon="CheckCircleIcon"
                        svgClasses="w-6 h-6 text-success"
                        class="mr-1"
                      ></feather-icon>
                      <feather-icon
                        v-if="candyManCarl.ipIsCorrect === false"
                        icon="XCircleIcon"
                        svgClasses="w-6 h-6 text-danger"
                        class="mr-1"
                      ></feather-icon>
                      <h4>IP Address</h4>
                    </div>
                    <vs-input placeholder="Enter IP Address" v-model="candyManCarl.ip" class="w-full" />
                  </div>

                  <div class="mt-3">
                    <div class="flex items-center mb-1">
                      <feather-icon
                        v-if="candyManCarl.serviceIsCorrect === true"
                        icon="CheckCircleIcon"
                        svgClasses="w-6 h-6 text-success"
                        class="mr-1"
                      ></feather-icon>
                      <feather-icon
                        v-if="candyManCarl.serviceIsCorrect === false"
                        icon="XCircleIcon"
                        svgClasses="w-6 h-6 text-danger"
                        class="mr-1"
                      ></feather-icon>
                      <h4>Non-Compliant Service</h4>
                    </div>
                    <v-select
                      placeholder="Select Service"
                      class="w-full"
                      :options="nonCompliantServiceOptions"
                      v-model="candyManCarl.service"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-col w-1/3">
              <div class="m-3">
                <div class="p-4 border-2 border-solid rounded" style="background: #ff9f43">
                  <h2>Name: <span class="font-bold break-all">FarmerLaura.Local</span></h2>

                  <div class="mt-3">
                    <div class="flex items-center mb-1">
                      <feather-icon
                        v-if="farmerLaura.roleIsCorrect === true"
                        icon="CheckCircleIcon"
                        svgClasses="w-6 h-6 text-success"
                        class="mr-1"
                      ></feather-icon>
                      <feather-icon
                        v-if="farmerLaura.roleIsCorrect === false"
                        icon="XCircleIcon"
                        svgClasses="w-6 h-6 text-danger"
                        class="mr-1"
                      ></feather-icon>
                      <h4>Role</h4>
                    </div>
                    <v-select placeholder="Select Role" class="w-full" :options="roleOptions" v-model="farmerLaura.role" />
                  </div>

                  <div class="mt-3">
                    <div class="flex items-center mb-1">
                      <feather-icon
                        v-if="farmerLaura.ipIsCorrect === true"
                        icon="CheckCircleIcon"
                        svgClasses="w-6 h-6 text-success"
                        class="mr-1"
                      ></feather-icon>
                      <feather-icon
                        v-if="farmerLaura.ipIsCorrect === false"
                        icon="XCircleIcon"
                        svgClasses="w-6 h-6 text-danger"
                        class="mr-1"
                      ></feather-icon>
                      <h4>IP Address</h4>
                    </div>
                    <vs-input placeholder="Enter IP Address" v-model="farmerLaura.ip" class="w-full" />
                  </div>

                  <div class="mt-3">
                    <div class="flex items-center mb-1">
                      <feather-icon
                        v-if="farmerLaura.serviceIsCorrect === true"
                        icon="CheckCircleIcon"
                        svgClasses="w-6 h-6 text-success"
                        class="mr-1"
                      ></feather-icon>
                      <feather-icon
                        v-if="farmerLaura.serviceIsCorrect === false"
                        icon="XCircleIcon"
                        svgClasses="w-6 h-6 text-danger"
                        class="mr-1"
                      ></feather-icon>
                      <h4>Non-Compliant Service</h4>
                    </div>
                    <v-select
                      placeholder="Select Service"
                      class="w-full"
                      :options="nonCompliantServiceOptions"
                      v-model="farmerLaura.service"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-col w-1/3">
              <div class="m-3">
                <div class="p-4 border-2 border-solid rounded" style="background: #10ac84">
                  <h2>Name: <span class="font-bold break-all">SandwichSara.Local</span></h2>

                  <div class="mt-3">
                    <div class="flex items-center mb-1">
                      <feather-icon
                        v-if="sandwichSara.roleIsCorrect === true"
                        icon="CheckCircleIcon"
                        svgClasses="w-6 h-6 text-success"
                        class="mr-1"
                      ></feather-icon>
                      <feather-icon
                        v-if="sandwichSara.roleIsCorrect === false"
                        icon="XCircleIcon"
                        svgClasses="w-6 h-6 text-danger"
                        class="mr-1"
                      ></feather-icon>
                      <h4>Role</h4>
                    </div>
                    <v-select placeholder="Select Role" class="w-full" :options="roleOptions" v-model="sandwichSara.role" />
                  </div>

                  <div class="mt-3">
                    <div class="flex items-center mb-1">
                      <feather-icon
                        v-if="sandwichSara.ipIsCorrect === true"
                        icon="CheckCircleIcon"
                        svgClasses="w-6 h-6 text-success"
                        class="mr-1"
                      ></feather-icon>
                      <feather-icon
                        v-if="sandwichSara.ipIsCorrect === false"
                        icon="XCircleIcon"
                        svgClasses="w-6 h-6 text-danger"
                        class="mr-1"
                      ></feather-icon>
                      <h4>IP Address</h4>
                    </div>
                    <vs-input placeholder="Enter IP Address" v-model="sandwichSara.ip" class="w-full" />
                  </div>

                  <div class="mt-3">
                    <div class="flex items-center mb-1">
                      <feather-icon
                        v-if="sandwichSara.serviceIsCorrect === true"
                        icon="CheckCircleIcon"
                        svgClasses="w-6 h-6 text-success"
                        class="mr-1"
                      ></feather-icon>
                      <feather-icon
                        v-if="sandwichSara.serviceIsCorrect === false"
                        icon="XCircleIcon"
                        svgClasses="w-6 h-6 text-danger"
                        class="mr-1"
                      ></feather-icon>
                      <h4>Non-Compliant Service</h4>
                    </div>
                    <v-select
                      placeholder="Select Service"
                      class="w-full"
                      :options="nonCompliantServiceOptions"
                      v-model="sandwichSara.service"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="vx-row my-12">
            <div class="vx-col w-1/3">
              <div class="flex items-center justify-center">
                <div class="lineHeight01" />
              </div>
            </div>
            <div class="vx-col w-1/3">
              <div class="flex items-center justify-center">
                <div class="lineHeight02" />
                <div class="lineHeight01" />
                <div class="lineWidth01" />
              </div>
            </div>
            <div class="vx-col w-1/3">
              <div class="flex items-center justify-center">
                <div class="lineHeight01" />
              </div>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-1/5 flex items-center justify-center">
              <div class="w-full">
                <div class="p-6 border-2 border-solid rounded" style="background: #d63031">
                  <h1 class="font-bold text-center">You Are Here</h1>

                  <div class="flex items-center justify-center mt-5">
                    <div class="rounded-lg cursor-pointer m-2 p-4" style="background: black">
                      <h3 class="font-light text-center" @click="toggleConsole">Open Console</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-col w-2/5">
              <div class="m-3">
                <div class="p-4 border-2 border-solid rounded" style="background: #6c5ce7">
                  <h2>Name: <span class="font-bold break-all">FarmerTed.Local</span></h2>

                  <div class="mt-3">
                    <div class="flex items-center mb-1">
                      <feather-icon
                        v-if="farmerTed.roleIsCorrect === true"
                        icon="CheckCircleIcon"
                        svgClasses="w-6 h-6 text-success"
                        class="mr-1"
                      ></feather-icon>
                      <feather-icon
                        v-if="farmerTed.roleIsCorrect === false"
                        icon="XCircleIcon"
                        svgClasses="w-6 h-6 text-danger"
                        class="mr-1"
                      ></feather-icon>
                      <h4>Role</h4>
                    </div>
                    <v-select placeholder="Select Role" class="w-full" :options="roleOptions" v-model="farmerTed.role" />
                  </div>

                  <div class="mt-3">
                    <div class="flex items-center mb-1">
                      <feather-icon
                        v-if="farmerTed.ipIsCorrect === true"
                        icon="CheckCircleIcon"
                        svgClasses="w-6 h-6 text-success"
                        class="mr-1"
                      ></feather-icon>
                      <feather-icon
                        v-if="farmerTed.ipIsCorrect === false"
                        icon="XCircleIcon"
                        svgClasses="w-6 h-6 text-danger"
                        class="mr-1"
                      ></feather-icon>
                      <h4>IP Address</h4>
                    </div>
                    <vs-input placeholder="Enter IP Address" v-model="farmerTed.ip" class="w-full" />
                  </div>

                  <div class="mt-3">
                    <div class="flex items-center mb-1">
                      <feather-icon
                        v-if="farmerTed.serviceIsCorrect === true"
                        icon="CheckCircleIcon"
                        svgClasses="w-6 h-6 text-success"
                        class="mr-1"
                      ></feather-icon>
                      <feather-icon
                        v-if="farmerTed.serviceIsCorrect === false"
                        icon="XCircleIcon"
                        svgClasses="w-6 h-6 text-danger"
                        class="mr-1"
                      ></feather-icon>
                      <h4>Non-Compliant Service</h4>
                    </div>
                    <v-select
                      placeholder="Select Service"
                      class="w-full z-10"
                      :options="nonCompliantServiceOptions"
                      v-model="farmerTed.service"
                      v-on:open="pageExpand(true)"
                      v-on:close="pageExpand(false)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-col w-2/5">
              <div class="vx-row">
                <div class="vx-col w-1/3">
                  <div class="m-3 ml-4 mr-4">
                    <div class="rounded-lg p-4" style="background: #e17055">
                      <div class="flex items-center justify-center" style="min-height: 50px">
                        <div class="lineWidth02" />
                        <div class="lineWidth03" />
                        <img
                          src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/319175813431820288/firewall.png"
                          width="65"
                        />
                      </div>
                      <h4 class="text-center mt-4 break-all">Firewall</h4>
                    </div>
                    <div class="flex items-center justify-center">
                      <div class="lineHeight03" />
                    </div>
                    <div class="rounded-lg p-4" style="background: #0984e3">
                      <div class="flex items-center justify-center" style="min-height: 50px">
                        <img
                          src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/319175813431820288/cloud.png"
                          width="65"
                        />
                      </div>
                      <h4 class="text-center mt-4 break-all">Internet</h4>
                    </div>
                  </div>
                </div>
                <div class="vx-col w-2/3">
                  <div class="m-3">
                    <div class="p-3 rounded" style="background: #e17055; margin-top: -25px">
                      <h2>DMZ</h2>
                      <div class="p-3 mt-2 border-2 border-solid rounded" style="background: rgba(255, 255, 255, 0.12)">
                        <h2>Name: <span class="font-bold break-all">LunchTimeMike.Local</span></h2>

                        <div class="mt-3">
                          <div class="flex items-center mb-1">
                            <feather-icon
                              v-if="lunchTimeMike.roleIsCorrect === true"
                              icon="CheckCircleIcon"
                              svgClasses="w-6 h-6 text-success"
                              class="mr-1"
                            ></feather-icon>
                            <feather-icon
                              v-if="lunchTimeMike.roleIsCorrect === false"
                              icon="XCircleIcon"
                              svgClasses="w-6 h-6 text-danger"
                              class="mr-1"
                            ></feather-icon>
                            <h4>Role</h4>
                          </div>
                          <v-select placeholder="Select Role" class="w-full" :options="roleOptions" v-model="lunchTimeMike.role" />
                        </div>
                        <div class="mt-3">
                          <div class="flex items-center mb-1">
                            <feather-icon
                              v-if="lunchTimeMike.ipIsCorrect === true"
                              icon="CheckCircleIcon"
                              svgClasses="w-6 h-6 text-success"
                              class="mr-1"
                            ></feather-icon>
                            <feather-icon
                              v-if="lunchTimeMike.ipIsCorrect === false"
                              icon="XCircleIcon"
                              svgClasses="w-6 h-6 text-danger"
                              class="mr-1"
                            ></feather-icon>
                            <h4>IP Address</h4>
                          </div>
                          <vs-input placeholder="Enter IP Address" v-model="lunchTimeMike.ip" class="w-full" />
                        </div>
                        <div class="mt-3">
                          <div class="flex items-center mb-1">
                            <feather-icon
                              v-if="lunchTimeMike.serviceIsCorrect === true"
                              icon="CheckCircleIcon"
                              svgClasses="w-6 h-6 text-success"
                              class="mr-1"
                            ></feather-icon>
                            <feather-icon
                              v-if="lunchTimeMike.serviceIsCorrect === false"
                              icon="XCircleIcon"
                              svgClasses="w-6 h-6 text-danger"
                              class="mr-1"
                            ></feather-icon>
                            <h4>Non-Compliant Service</h4>
                          </div>
                          <v-select
                            placeholder="Select Service"
                            class="w-full z-10"
                            :options="nonCompliantServiceOptions"
                            v-on:open="pageExpand(true)"
                            v-on:close="pageExpand(false)"
                            v-model="lunchTimeMike.service"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>

      <movable-popup
        refVal="movableConsoleRef"
        title="Console - Click Here to Drag This Popup"
        :active="consoleOpen"
        v-on:close="consoleOpen = false"
      >
        <template v-slot:content>
          <vue-command
            id="command_prompt"
            :commands="commands"
            prompt="[root@rapid-simulations ~]>"
            class="rounded"
            intro="Type [help] to show available commands"
            :show-intro="true"
            style="background-color: #111111"
            title="Command Prompt"
          />
        </template>
      </movable-popup>
    </div>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import VueCommand, { createStdout, createStderr } from 'vue-command';
import 'vue-command/dist/vue-command.css';
import vSelect from 'vue-select';
import MovablePopup from '../../../../../../../../components/popup/MovablePopup';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      expandPage: false,
      consoleOpen: false,

      commands: {
        mema: () => createStdout('Is the best developer in the world'),
        help: () => createStdout('Available commands:<br>nmap -host-<br>ping -host-<br>help'),
        nmap: () => undefined,
        ping: () => undefined,
      },

      ranPing: false,
      ranNmap: false,

      roleOptions: ['File Server', 'Database', 'Mail Server', 'Web Server', 'Switch'],
      nonCompliantServiceOptions: [
        'IMAP/S 993',
        'SMTP 25',
        'HTTP 80',
        'NetBIOS 139',
        'RPC 135',
        'FTP 21',
        'Telnet 23',
        'HTTPS 443',
        'IMAP 143',
        'SSH 22',
        'MYSQL 3306',
        'DNS 53',
        'SMB/CIFS 445',
      ],

      candyManCarl: {
        role: '',
        roleAnswer: 'File Server',
        roleIsCorrect: null,
        ip: '',
        ipAnswer: '10.0.100.51',
        ipIsCorrect: null,
        service: '',
        serviceAnswer: 'FTP 21',
        serviceIsCorrect: null,
      },
      farmerLaura: {
        role: '',
        roleAnswer: 'Database',
        roleIsCorrect: null,
        ip: '',
        ipAnswer: '10.0.100.52',
        ipIsCorrect: null,
        service: '',
        serviceAnswer: 'DNS 53',
        serviceIsCorrect: null,
      },
      sandwichSara: {
        role: '',
        roleAnswer: 'Mail Server',
        roleIsCorrect: null,
        ip: '',
        ipAnswer: '10.0.100.53',
        ipIsCorrect: null,
        service: '',
        serviceAnswer: 'SMTP 25',
        serviceIsCorrect: null,
      },
      farmerTed: {
        role: '',
        roleAnswer: 'Switch',
        roleIsCorrect: null,
        ip: '',
        ipAnswer: '10.0.100.54',
        ipIsCorrect: null,
        service: '',
        serviceAnswer: 'NetBIOS 139',
        serviceIsCorrect: null,
      },
      lunchTimeMike: {
        role: '',
        roleAnswer: 'Web Server',
        roleIsCorrect: null,
        ip: '',
        ipAnswer: '10.0.100.55',
        ipIsCorrect: null,
        service: '',
        serviceAnswer: 'IMAP 143',
        serviceIsCorrect: null,
      },
    };
  },
  methods: {
    pageExpand(expand) {
      if (expand === true) {
        this.expandPage = true;
        return;
      }
      setTimeout(() => {
        this.expandPage = false;
      }, 215);
    },
    toggleConsole() {
      if (this.consoleOpen === true) {
        this.consoleOpen = 'transition_out';
        return setTimeout(() => {
          this.consoleOpen = false;
        }, 400);
      }
      this.consoleOpen = true;
    },
    markSimulation() {
      let totalScore = 0;

      if (this.candyManCarl.role === this.candyManCarl.roleAnswer) {
        totalScore++;
        this.candyManCarl.roleIsCorrect = true;
      } else {
        this.candyManCarl.roleIsCorrect = false;
      }
      if (this.candyManCarl.ip === this.candyManCarl.ipAnswer) {
        totalScore++;
        this.candyManCarl.ipIsCorrect = true;
      } else {
        this.candyManCarl.ipIsCorrect = false;
      }
      if (this.candyManCarl.service === this.candyManCarl.serviceAnswer) {
        totalScore++;
        this.candyManCarl.serviceIsCorrect = true;
      } else {
        this.candyManCarl.serviceIsCorrect = false;
      }

      if (this.farmerLaura.role === this.farmerLaura.roleAnswer) {
        totalScore++;
        this.farmerLaura.roleIsCorrect = true;
      } else {
        this.farmerLaura.roleIsCorrect = false;
      }
      if (this.farmerLaura.ip === this.farmerLaura.ipAnswer) {
        totalScore++;
        this.farmerLaura.ipIsCorrect = true;
      } else {
        this.farmerLaura.ipIsCorrect = false;
      }
      if (this.farmerLaura.service === this.farmerLaura.serviceAnswer) {
        totalScore++;
        this.farmerLaura.serviceIsCorrect = true;
      } else {
        this.farmerLaura.serviceIsCorrect = false;
      }

      if (this.sandwichSara.role === this.sandwichSara.roleAnswer) {
        totalScore++;
        this.sandwichSara.roleIsCorrect = true;
      } else {
        this.sandwichSara.roleIsCorrect = false;
      }
      if (this.sandwichSara.ip === this.sandwichSara.ipAnswer) {
        totalScore++;
        this.sandwichSara.ipIsCorrect = true;
      } else {
        this.sandwichSara.ipIsCorrect = false;
      }
      if (this.sandwichSara.service === this.sandwichSara.serviceAnswer) {
        totalScore++;
        this.sandwichSara.serviceIsCorrect = true;
      } else {
        this.sandwichSara.serviceIsCorrect = false;
      }

      if (this.farmerTed.role === this.farmerTed.roleAnswer) {
        totalScore++;
        this.farmerTed.roleIsCorrect = true;
      } else {
        this.farmerTed.roleIsCorrect = false;
      }
      if (this.farmerTed.ip === this.farmerTed.ipAnswer) {
        totalScore++;
        this.farmerTed.ipIsCorrect = true;
      } else {
        this.farmerTed.ipIsCorrect = false;
      }
      if (this.farmerTed.service === this.farmerTed.serviceAnswer) {
        totalScore++;
        this.farmerTed.serviceIsCorrect = true;
      } else {
        this.farmerTed.serviceIsCorrect = false;
      }

      if (this.lunchTimeMike.role === this.lunchTimeMike.roleAnswer) {
        totalScore++;
        this.lunchTimeMike.roleIsCorrect = true;
      } else {
        this.lunchTimeMike.roleIsCorrect = false;
      }
      if (this.lunchTimeMike.ip === this.lunchTimeMike.ipAnswer) {
        totalScore++;
        this.lunchTimeMike.ipIsCorrect = true;
      } else {
        this.lunchTimeMike.ipIsCorrect = false;
      }
      if (this.lunchTimeMike.service === this.lunchTimeMike.serviceAnswer) {
        totalScore++;
        this.lunchTimeMike.serviceIsCorrect = true;
      } else {
        this.lunchTimeMike.serviceIsCorrect = false;
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  mounted() {
    this.commands.ping = (x) => {
      // CandyManCarl.Local && 10.0.100.51
      if (x._[1] === 'CandyManCarl.Local') {
        return createStdout(
          'PING CandyManCarl.Local (10.0.100.51) 56(84) bytes of data.<br> 64 bytes from 10.0.100.51: icmp_seq=1 ttl=64 time=0.015 ms<br> 64 bytes from 10.0.100.51: icmp_seq=2 ttl=64 time=0.017 ms<br> 64 bytes from 10.0.100.51: icmp_seq=3 ttl=64 time=0.014 ms<br> <br> --- 10.0.100.51 ping statistics ---<br> 3 packets transmitted, 3 received, 0% packet loss, time 2051ms<br> rtt min/avg/max/mdev = 0.014/0.015/0.017/0.001 ms<br> <br>',
        );
      }

      if (x._[1] === '10.0.100.51') {
        return createStdout(
          'PING 10.0.100.51 (10.0.100.51) 56(84) bytes of data.<br> 64 bytes from 10.0.100.51: icmp_seq=1 ttl=64 time=0.015 ms<br> 64 bytes from 10.0.100.51: icmp_seq=2 ttl=64 time=0.017 ms<br> 64 bytes from 10.0.100.51: icmp_seq=3 ttl=64 time=0.014 ms<br> <br> --- 10.0.100.51 ping statistics ---<br> 3 packets transmitted, 3 received, 0% packet loss, time 2051ms<br> rtt min/avg/max/mdev = 0.014/0.015/0.017/0.001 ms<br> <br>',
        );
      }

      // FarmerLaura.Local & 10.0.100.52
      if (x._[1] === 'FarmerLaura.Local') {
        return createStdout(
          'PING FarmerLaura.Local (10.0.100.52) 56(84) bytes of data.<br> 64 bytes from 10.0.100.52: icmp_seq=1 ttl=64 time=0.015 ms<br> 64 bytes from 10.0.100.52: icmp_seq=2 ttl=64 time=0.017 ms<br> 64 bytes from 10.0.100.52: icmp_seq=3 ttl=64 time=0.014 ms<br> <br> --- 10.0.100.52 ping statistics ---<br> 3 packets transmitted, 3 received, 0% packet loss, time 2051ms<br> rtt min/avg/max/mdev = 0.014/0.015/0.017/0.001 ms<br>',
        );
      }

      if (x._[1] === '10.0.100.52') {
        return createStdout(
          'PING 10.0.100.52 (10.0.100.52) 56(84) bytes of data.<br> 64 bytes from 10.0.100.52: icmp_seq=1 ttl=64 time=0.015 ms<br> 64 bytes from 10.0.100.52: icmp_seq=2 ttl=64 time=0.017 ms<br> 64 bytes from 10.0.100.52: icmp_seq=3 ttl=64 time=0.014 ms<br> <br> --- 10.0.100.52 ping statistics ---<br> 3 packets transmitted, 3 received, 0% packet loss, time 2051ms<br> rtt min/avg/max/mdev = 0.014/0.015/0.017/0.001 ms<br>',
        );
      }

      // SandwichSara.Local & 10.0.100.53
      if (x._[1] === 'SandwichSara.Local') {
        return createStdout(
          'PING SandwichSara.Local (10.0.100.53) 56(84) bytes of data.<br> 64 bytes from 10.0.100.53: icmp_seq=1 ttl=64 time=0.015 ms<br> 64 bytes from 10.0.100.53: icmp_seq=2 ttl=64 time=0.017 ms<br> 64 bytes from 10.0.100.53: icmp_seq=3 ttl=64 time=0.014 ms<br> <br> --- 10.0.100.53 ping statistics ---<br> 3 packets transmitted, 3 received, 0% packet loss, time 2051ms<br> rtt min/avg/max/mdev = 0.014/0.015/0.017/0.001 ms<br>',
        );
      }

      if (x._[1] === '10.0.100.53') {
        return createStdout(
          'PING 10.0.100.53 (10.0.100.53) 56(84) bytes of data.<br> 64 bytes from 10.0.100.53: icmp_seq=1 ttl=64 time=0.015 ms<br> 64 bytes from 10.0.100.53: icmp_seq=2 ttl=64 time=0.017 ms<br> 64 bytes from 10.0.100.53: icmp_seq=3 ttl=64 time=0.014 ms<br> <br> --- 10.0.100.53 ping statistics ---<br> 3 packets transmitted, 3 received, 0% packet loss, time 2051ms<br> rtt min/avg/max/mdev = 0.014/0.015/0.017/0.001 ms<br>',
        );
      }

      // FarmerTed.Local & 10.0.100.54
      if (x._[1] === 'FarmerTed.Local') {
        return createStdout(
          'PING FarmerTed.Local (10.0.100.54) 56(84) bytes of data.<br> 64 bytes from 10.0.100.54: icmp_seq=1 ttl=64 time=0.015 ms<br> 64 bytes from 10.0.100.54: icmp_seq=2 ttl=64 time=0.017 ms<br> 64 bytes from 10.0.100.54: icmp_seq=3 ttl=64 time=0.014 ms<br> <br> --- 10.0.100.54 ping statistics ---<br> 3 packets transmitted, 3 received, 0% packet loss, time 2051ms<br> rtt min/avg/max/mdev = 0.014/0.015/0.017/0.001 ms<br>',
        );
      }

      if (x._[1] === '10.0.100.54') {
        return createStdout(
          'PING 10.0.100.54 (10.0.100.54) 56(84) bytes of data.<br> 64 bytes from 10.0.100.54: icmp_seq=1 ttl=64 time=0.015 ms<br> 64 bytes from 10.0.100.54: icmp_seq=2 ttl=64 time=0.017 ms<br> 64 bytes from 10.0.100.54: icmp_seq=3 ttl=64 time=0.014 ms<br> <br> --- 10.0.100.54 ping statistics ---<br> 3 packets transmitted, 3 received, 0% packet loss, time 2051ms<br> rtt min/avg/max/mdev = 0.014/0.015/0.017/0.001 ms<br>',
        );
      }

      // LunchTimeMike.Local & 10.0.100.55
      if (x._[1] === 'LunchTimeMike.Local') {
        return createStdout(
          'PING LunchTimeMike.Local (10.0.100.55) 56(84) bytes of data.<br> 64 bytes from 10.0.100.55: icmp_seq=1 ttl=64 time=0.015 ms<br> 64 bytes from 10.0.100.55: icmp_seq=2 ttl=64 time=0.017 ms<br> 64 bytes from 10.0.100.55: icmp_seq=3 ttl=64 time=0.014 ms<br> <br> --- 10.0.100.55 ping statistics ---<br> 3 packets transmitted, 3 received, 0% packet loss, time 2051ms<br> rtt min/avg/max/mdev = 0.014/0.015/0.017/0.001 ms<br>',
        );
      }

      if (x._[1] === '10.0.100.55') {
        return createStdout(
          '---Cm<br> PING 10.0.100.55 (10.0.100.55) 56(84) bytes of data.<br> 64 bytes from 10.0.100.55: icmp_seq=1 ttl=64 time=0.015 ms<br> 64 bytes from 10.0.100.55: icmp_seq=2 ttl=64 time=0.017 ms<br> 64 bytes from 10.0.100.55: icmp_seq=3 ttl=64 time=0.014 ms<br> <br> --- 10.0.100.55 ping statistics ---<br> 3 packets transmitted, 3 received, 0% packet loss, time 2051ms<br> rtt min/avg/max/mdev = 0.014/0.015/0.017/0.001 ms<br>',
        );
      }

      return createStderr('<span class="text-danger">‎ ‎ ‎  %‎‎ ‎‎ ‎Invalid Input.</span>');
    };
    this.commands.nmap = (x) => {
      // CandyManCarl.Local && 10.0.100.51
      if (x._[1] === 'CandyManCarl.Local') {
        return createStdout(
          'Starting Nmap 7.91 ( https://nmap.org ) at 2021-01-02 19:17 Eastern Standard Time<br> Nmap scan report for CandyManCarl.Local (10.0.100.51)<br> Host is up (0.039s latency).<br> Not shown: 99 filtered ports<br> <br> PORT   STATE SERVICE VERSION<br> 21/tcp   open     ftp         crushFTPd<br> 22/tcp   open     sftp          Solar Winds sftpd<br> 135/tcp  filtered msrpc<br> 139/tcp  filtered netbios-ssn<br> 445/tcp  filtered microsoft-ds<br> 1688/tcp filtered nsjtp-data<br> <br> Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br> Device type: general purpose<br> Running: Linux 4.X|5.X<br> OS CPE: cpe:/o:linux:linux_kernel:4 cpe:/o:linux:linux_kernel:5<br> OS details: Linux 4.15 - 5.6<br> Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br> <br> OS and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br> <br> Nmap done: 1 IP address (1 host up) scanned in 7.04 seconds<br> <br>',
        );
      }

      if (x._[1] === '10.0.100.51') {
        return createStdout(
          'Starting Nmap 7.91 ( https://nmap.org ) at 2021-01-02 19:17 Eastern Standard Time<br> Nmap scan report for 10.0.100.51 (10.0.100.51)<br> Host is up (0.039s latency).<br> Not shown: 99 filtered ports<br> <br> PORT   STATE SERVICE VERSION<br> 21/tcp   open     ftp         crushFTPd<br> 22/tcp   open     sftp          Solar Winds sftpd<br> 135/tcp  filtered msrpc<br> 139/tcp  filtered netbios-ssn<br> 445/tcp  filtered microsoft-ds<br> 1688/tcp filtered nsjtp-data<br> <br> Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br> Device type: general purpose<br> Running: Linux 4.X|5.X<br> OS CPE: cpe:/o:linux:linux_kernel:4 cpe:/o:linux:linux_kernel:5<br> OS details: Linux 4.15 - 5.6<br> Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br> <br> OS and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br> <br> Nmap done: 1 IP address (1 host up) scanned in 7.04 seconds<br> <br>>',
        );
      }

      // FarmerLaura.Local & 10.0.100.52
      if (x._[1] === 'FarmerLaura.Local') {
        return createStdout(
          'Starting Nmap 7.91 ( https://nmap.org ) at 2021-01-02 19:17 Eastern Standard Time<br> Nmap scan report for FarmerLaura.Local (10.0.100.52)<br> Host is up (0.039s latency).<br> Not shown: 99 filtered ports<br> <br> PORT   STATE SERVICE VERSION<br> 53/tcp   open msdns<br> 3306/tcp   open     mysql<br> 135/tcp  filtered msrpc<br> 139/tcp  filtered netbios-ssn<br> 445/tcp  filtered microsoft-ds<br> 1688/tcp filtered nsjtp-data<br> <br> Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br> Device type: general purpose<br> Running: Linux 4.X|5.X<br> OS CPE: cpe:/o:linux:linux_kernel:4 cpe:/o:linux:linux_kernel:5<br> OS details: Linux 4.15 - 5.6<br> Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br> <br> OS and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br> <br> Nmap done: 1 IP address (1 host up) scanned in 7.04 seconds<br> <br>',
        );
      }

      if (x._[1] === '10.0.100.52') {
        return createStdout(
          'Starting Nmap 7.91 ( https://nmap.org ) at 2021-01-02 19:17 Eastern Standard Time<br> Nmap scan report for 10.0.100.52 (10.0.100.52)<br> Host is up (0.039s latency).<br> Not shown: 99 filtered ports<br> <br> PORT   STATE SERVICE VERSION<br> 53/tcp   open msdns<br> 3306/tcp   open     mysql<br> 135/tcp  filtered msrpc<br> 139/tcp  filtered netbios-ssn<br> 445/tcp  filtered microsoft-ds<br> 1688/tcp filtered nsjtp-data<br> <br> Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br> Device type: general purpose<br> Running: Linux 4.X|5.X<br> OS CPE: cpe:/o:linux:linux_kernel:4 cpe:/o:linux:linux_kernel:5<br> OS details: Linux 4.15 - 5.6<br> Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br> <br> OS and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br> <br> Nmap done: 1 IP address (1 host up) scanned in 7.04 seconds<br> <br>',
        );
      }

      // SandwichSara.Local & 10.0.100.53
      if (x._[1] === 'SandwichSara.Local') {
        return createStdout(
          'Starting Nmap 7.91 ( https://nmap.org ) at 2021-01-02 19:17 Eastern Standard Time<br> Nmap scan report for SandwichSara.Local (10.0.100.53)<br> Host is up (0.039s latency).<br> Not shown: 99 filtered ports<br> <br> PORT   STATE SERVICE VERSION<br> 587/tcp   open smtpd<br> 25/tcp   open smtpd<br> 135/tcp  filtered msrpc<br> 139/tcp  filtered netbios-ssn<br> 445/tcp  filtered microsoft-ds<br> 1688/tcp filtered nsjtp-data<br> <br> Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br> Device type: general purpose<br> Running: Linux 4.X|5.X<br> OS CPE: cpe:/o:linux:linux_kernel:4 cpe:/o:linux:linux_kernel:5<br> OS details: Linux 4.15 - 5.6<br> Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br> <br> OS and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br> <br> Nmap done: 1 IP address (1 host up) scanned in 7.04 seconds<br> <br>',
        );
      }

      if (x._[1] === '10.0.100.53') {
        return createStdout(
          'Starting Nmap 7.91 ( https://nmap.org ) at 2021-01-02 19:17 Eastern Standard Time<br> Nmap scan report for 10.0.100.53 (10.0.100.53)<br> Host is up (0.039s latency).<br> Not shown: 99 filtered ports<br> <br> PORT   STATE SERVICE VERSION<br> 587/tcp   open smtpd<br> 25/tcp   open smtpd<br> 135/tcp  filtered msrpc<br> 139/tcp  filtered netbios-ssn<br> 445/tcp  filtered microsoft-ds<br> 1688/tcp filtered nsjtp-data<br> <br> Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br> Device type: general purpose<br> Running: Linux 4.X|5.X<br> OS CPE: cpe:/o:linux:linux_kernel:4 cpe:/o:linux:linux_kernel:5<br> OS details: Linux 4.15 - 5.6<br> Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br> <br> OS and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br> <br> Nmap done: 1 IP address (1 host up) scanned in 7.04 seconds<br> <br>',
        );
      }

      // FarmerTed.Local & 10.0.100.54
      if (x._[1] === 'FarmerTed.Local') {
        return createStdout(
          'Starting Nmap 7.91 ( https://nmap.org ) at 2021-01-02 19:17 Eastern Standard Time<br> Nmap scan report for FarmerTed.Local (10.0.100.54)<br> Host is up (0.039s latency).<br> Not shown: 99 filtered ports<br> <br> PORT   STATE SERVICE VERSION<br> 443/tcp   open     https<br> 135/tcp  filtered msrpc<br> 139/tcp open netbios-ssn<br> 445/tcp  filtered microsoft-ds<br> 1688/tcp filtered nsjtp-data<br> <br> Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br> Device type: general purpose<br> Running: Linux 4.X|5.X<br> OS CPE: cpe:/o:linux:linux_kernel:4 cpe:/o:linux:linux_kernel:5<br> OS details: Linux 4.15 - 5.6<br> Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br> <br> OS and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br> <br> Nmap done: 1 IP address (1 host up) scanned in 7.04 seconds<br> <br>',
        );
      }

      if (x._[1] === '10.0.100.54') {
        return createStdout(
          'Starting Nmap 7.91 ( https://nmap.org ) at 2021-01-02 19:17 Eastern Standard Time<br> Nmap scan report for 10.0.100.54 (10.0.100.54)<br> Host is up (0.039s latency).<br> Not shown: 99 filtered ports<br> <br> PORT   STATE SERVICE VERSION<br> 443/tcp   open     https<br> 135/tcp  filtered msrpc<br> 139/tcp open netbios-ssn<br> 445/tcp  filtered microsoft-ds<br> 1688/tcp filtered nsjtp-data<br> <br> Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br> Device type: general purpose<br> Running: Linux 4.X|5.X<br> OS CPE: cpe:/o:linux:linux_kernel:4 cpe:/o:linux:linux_kernel:5<br> OS details: Linux 4.15 - 5.6<br> Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br> <br> OS and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br> <br> Nmap done: 1 IP address (1 host up) scanned in 7.04 seconds<br> <br>',
        );
      }

      // LunchTimeMike.Local & 10.0.100.55
      if (x._[1] === 'LunchTimeMike.Local') {
        return createStdout(
          'Starting Nmap 7.91 ( https://nmap.org ) at 2021-01-02 19:17 Eastern Standard Time<br> Nmap scan report for LunchTimeMike.Local (10.0.100.55)<br> Host is up (0.039s latency).<br> Not shown: 99 filtered ports<br> <br> PORT   STATE SERVICE VERSION<br>443/tcp   open     https     Apache httpd 2.4.48 ((Ubuntu))<br> 143/tcp open imap<br> 139/tcp filtered netbios-ssn<br> 445/tcp  filtered microsoft-ds<br> 1688/tcp filtered nsjtp-data<br> <br> Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br> Device type: general purpose<br> Running: Linux 4.X|5.X<br> OS CPE: cpe:/o:linux:linux_kernel:4 cpe:/o:linux:linux_kernel:5<br> OS details: Linux 4.15 - 5.6<br> Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br> <br> OS and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br> <br> Nmap done: 1 IP address (1 host up) scanned in 7.04 seconds<br> <br>',
        );
      }

      if (x._[1] === '10.0.100.55') {
        return createStdout(
          'Starting Nmap 7.91 ( https://nmap.org ) at 2021-01-02 19:17 Eastern Standard Time<br> Nmap scan report for 10.0.100.55 (10.0.100.55)<br> Host is up (0.039s latency).<br> Not shown: 99 filtered ports<br> <br> PORT   STATE SERVICE VERSION<br>443/tcp   open     https     Apache httpd 2.4.48 ((Ubuntu))<br> 143/tcp open imap<br> 139/tcp filtered netbios-ssn<br> 445/tcp  filtered microsoft-ds<br> 1688/tcp filtered nsjtp-data<br> <br> Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br> Device type: general purpose<br> Running: Linux 4.X|5.X<br> OS CPE: cpe:/o:linux:linux_kernel:4 cpe:/o:linux:linux_kernel:5<br> OS details: Linux 4.15 - 5.6<br> Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br> <br> OS and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br> <br> Nmap done: 1 IP address (1 host up) scanned in 7.04 seconds<br> <br>',
        );
      }

      return createStderr('<span class="text-danger">‎ ‎ ‎  %‎‎ ‎‎ ‎Invalid Input.</span>');
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    Prism,
    VueCommand,
    vSelect,
    MovablePopup,
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
};
</script>
<style scoped>
. {
  z-index: 66;
}

.lineWidth01 {
  position: absolute;
  width: 66%;
  height: 5px;
  background-color: #ffffff;
  z-index: 0;
}

.lineWidth02 {
  position: absolute;
  width: 69px;
  margin-left: 12.1%;
  height: 5px;
  background-color: #e17055;
  z-index: 0;
}

.lineWidth03 {
  position: absolute;
  width: 69px;
  margin-right: 12.1%;
  height: 5px;
  background-color: #e17055;
  z-index: 0;
}

.lineHeight01 {
  position: absolute;
  width: 5px;
  height: 55px;
  margin-bottom: 49px;
  background-color: #ffffff;
  z-index: 0;
}

.lineHeight02 {
  position: absolute;
  width: 5px;
  height: 56px;
  margin-top: 49px;
  background-color: #ffffff;
  z-index: 0;
}

.lineHeight03 {
  width: 5px;
  height: 40px;
  background-color: #e17055;
  z-index: 0;
}
</style>
